<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction, downloadAction, getParams } from '@/command/netTool'
export default {
  name: 'specialty',
  data() {
    return {
      ...api.command.getState(),
      categoriesList: [],
      freightData: []
    }
  },
  activated() {
    let { freightTemplate } = this.$route.query
    if (freightTemplate) {
      this.getList(freightTemplate)
    }
  },
  created() {
    getAction('/api/config/farmGoodsCategory/list').then(res => {
      const { data } = res
      if (data && data.length) {
        this.categoriesList = data.map(e => {
          return {
            text: e.name,
            name: e.name,
            value: e.id
          }
        })
      }
    })
    getAction('/api/config/farmFreight/list').then(res => {
      const { data } = res
      if (data && data.length) {
        this.freightData = data.map(el => {
          return {
            name: el.name,
            text: el.name,
            value: el.id
          }
        })
      }
    })
  },
  mounted() {
    let { freightTemplate } = this.$route.query
    this.getList(freightTemplate)
  },
  methods: {
    getList(freightTemplate) {
      api.command.getList.call(this, {
        url: '/commodity/farmNativeProduct/page',
        current: 1,
        paramsValue: {
          freightTemplate
        }
      })
    },
    getHeader() {
      return [
        {
          name: '特产名称',
          key: 'nativeProductName',
          type: 'input'
        },
        {
          name: '店铺名称',
          key: 'linkShop',
          type: 'input'
        },
        {
          name: 'ID',
          key: 'id',
          type: 'input'
        },
        {
          name: '现价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低现价',
              key: 'minPrice'
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center'
            },
            {
              type: 'input',
              cols: 11,
              label: '最高现价',
              key: 'maxPrice'
            }
          ]
        },
        {
          name: '会员价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低会员价',
              key: 'minVipPrice'
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center'
            },
            {
              type: 'input',
              cols: 11,
              label: '最高会员价',
              key: 'maxVipPrice'
            }
          ]
        },
        {
          name: '分类',
          key: 'categoriesId',
          type: 'select',
          typeData: this.categoriesList
        },
        {
          name: '运费模板',
          key: 'freightTemplateId',
          type: 'select',
          typeData: this.freightData
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'nativeProductName',
          title: '特产名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.bannerUrl ? <img src={records.bannerUrl.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     return `${records.bannerUrl} / ${text}`
          //   },
          sorter: (a, b) => a.nativeProductName.length - b.nativeProductName.length
        },
        {
          dataIndex: 'farmNativeProductStandardsVOS',
          title: '规格',
          getData: records => records.farmNativeProductStandardsVOS.slice(0, 3).map(e => e.standardsName),
          type: 'tagGroup',
          align: 'left',
          onExport: (text, records) => {
            let arr = text.map(e => {
              return e.standardsName
            })
            return arr.toString()
          }
        },
        {
          dataIndex: 'linkShop',
          title: '店铺名称',
          align: 'left'
        },
        {
          dataIndex: 'salePrice',
          title: '现价',
          align: 'left',
          customRender: function(text, records) {
            return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
          }
        },
        {
          dataIndex: 'vipPrice',
          title: '会员价',
          align: 'left',
          customRender: function(text, records) {
            return records.vipPrice == null ? '' : <div>{'￥' + records.vipPrice}</div>
          }
        },
        {
          dataIndex: 'categories',
          title: '分类',
          align: 'left'
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a7',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/commodity/farmNativeProduct/${records.upDown == 0 ? 'down' : 'up'}/${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/specialtyDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `commodity/farmNativeProduct/${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/specialtyDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/commodity/farmNativeProduct/batchDel',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/commodity/farmNativeProduct/batchUp',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/commodity/farmNativeProduct/batchDown',
                  params: { idList }
                })
              }
            }
          ]
        },
        {
          name: '同步商品',
          type: 'primary',
          onClick: () => this.$router.push('/commodityManagement/specialtyGoods')
        }
      ]
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: data => {
            downloadAction(`/api/commodity/farmNativeProduct/exportData${getParams(data)}`, '农家特产.xlsx')
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.getRightButton()}
        filterRightButtonKey={['refresh', 'setting']}
        records={this.records}
      />
    )
  }
}
</script>
